<template>
  <div class="wrap">
<!--    <div class="header">
      售后消息
    </div>-->
    <div class="list">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getData"
      >
        <div class="item" v-for="message in messages" :key="message.orderId" @click="toMessageDetailPage(message.orderId, message.oUserId)">
          <div class="image">
            <img v-if="message.order && message.order.goods && message.order.goods.length" :src="message.order.goods[0].image" alt="">
          </div>
          <div class="info">
            <div class="title">
              {{ message.lastText }}
            </div>
            <div class="other">
              <div class="name">{{ message.accountInfo ? message.accountInfo.name : '???' }}</div>
              <div class="date">{{ message.updateTime }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import {getMessageList} from "@/api/message";

export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      messages: [],
      loading: false,
      finished: false,
    }
  },
  created() {

  },
  methods: {
    getData() {
      const handle = this.$route.query.handle;
      getMessageList(handle, this.page, this.pageSize)
        .then(res => {
          this.loading = false;
          if (res.error === 0) {
            if (res.data.length) {
              this.messages = [...this.messages, ...res.data];
              this.page++;
            } else {
              this.finished = true;
            }
          } else {
            this.finished = true;
            this.$errorMessage(res.message);
          }
        })
        .catch(error => {
          this.loading = false;
          this.finished = true;
          console.log('请求问题列表失败', error)
        })
    },
    toMessageDetailPage(orderId, oUserId) {
      this.$router.push({
        name: 'messageDetail',
        params: {
          orderId,
          oUserId,
        }
      });
    }

  }
}
</script>

<style lang="less" scoped>
  .header{
    font-size: 1.2rem;
    padding: .5rem 0;
    color: #000000;
    background-color: #f2f2f2;
    font-weight: bold;
  }
  .list{
    padding: 0 .8rem;
    .item{
      display: flex;
      border-bottom: 1px solid #cccccc;
      padding: .6rem 0;
      .image{
        flex: 0 0 auto;
        width: 3.5rem;
        height: 3.5rem;
        background-color: #666666;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .info{
        flex: 1 1 auto;
        font-size: .8rem;
        padding-left: 10px;
        .title{
          text-align: left;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          height: 2rem;
          line-height: 1rem;
        }
        .other{
          padding: .5rem 0 0;
          display: flex;
          justify-content: space-between;
          color: #999999;
        }
      }
    }
  }
</style>
