<template>
  <div class="wrap">
    <div class="status">
      <div class="handled" v-if="question.status === 'ANSWERED'">
        已回复:
        <template v-if="question.answerEnv === -1">mercado,</template>
        <template v-else-if="question.answerEnv === 1">PC端-MinoTrend,</template>
        <template v-else-if="question.answerEnv === 2">微信端-{{ question.weixinUserInfo ? question.weixinUserInfo.nickname : '昵称获取失败' }},</template>
        <template v-else></template>
        {{ question.answerDateCreated }}
      </div>
      <div class="un-handle" v-if="question.status === 'UNANSWERED'">状态：未回复</div>
    </div>
    <div class="goods">
      <div class="title">咨询商品：</div>
      <div class="goods-wrap">
        <div class="image" @click="toGoodsOriginPage(question.goods.goodsId)">
          <img v-if="question.goods" :src="question.goods.image" alt="">
        </div>
        <div class="info">
          <div class="title">
            {{ question.goods ? question.goods.title : '' }}
          </div>
        </div>
      </div>
      <div class="other">
        <div class="lt">
          <div class="customer-name">{{ question.customerName ? question.customerName: '---' }}</div>
          <div class="date">{{ question.dateCreated }}</div>
        </div>
        <div class="rt">
          <div class="site" v-if="question.sellerInfo">
            <img :src="require('@/assets/site_images/' + question.sellerInfo.siteId +'.png' )" alt="">
            <div class="full" v-show="question.sellerInfo.isFull === 1 ">FULL</div>
          </div>
          <div class="name">{{ question.accountInfo ? question.accountInfo.name : '???' }}</div>
        </div>
      </div>
    </div>
    <div class="text text-translated">
      <div class="title-wrap">
        <span class="title">咨询问题：</span>
        <div class="right">
          <van-button class="same-message image-button" :icon="require('@/assets/images/same-message.png')" round @click="showSameMessagePopup()" v-show="sameCustomerMessages.length">直达售后</van-button>
          <van-button class="history image-button" :icon="require('@/assets/images/history.png')" round @click="toHistoryPage" v-show="question.historyCount > 1">历史咨询</van-button>
        </div>
      </div>
      <div class="box" @click="copyText(question.textTranslated)">
        {{ question.textTranslated }}
        <div class="copy-tips">
          点击文本框即可复制
        </div>
      </div>
    </div>
    <div class="text">
      <div class="title">
        翻译后（英语）：
      </div>
      <div class="box" @click="copyText(question.text)">
        {{ question.text }}
        <div class="copy-tips">
          点击文本框即可复制
        </div>
      </div>
      <div class="ext">
        Translated by MercadoLibre
      </div>
    </div>
    <div class="block"></div>
    <div class="text answer">
      <div class="text-wrap">
        <div class="title">您的回复：</div>
        <div class="buttons" v-if="question.status === 'UNANSWERED' && answerLanguage">
          <div class="lang en" :class="{current: answerLanguage === 'en'}" @click="answer = '';setLanguage('en')">使用英语</div>
          <div class="lang local" :class="{current: answerLanguage === 'local'}" @click="answer = '';setLanguage('local')">使用当地语言</div>
        </div>
      </div>
      <template v-if="question.status === 'UNANSWERED' && !answerLanguage">
        <div class="select-language">
          <div class="language en" @click="setLanguage('en')">
            <div class="title">使用英语回复</div>
            <img :src="require('@/assets/images/answer-language-en.png')" alt="">
          </div>
          <div class="language local" @click="setLanguage('local')">
            <div class="title">使用当地语言回复</div>
            <img :src="require('@/assets/images/answer-language-local.png')" alt="">
          </div>
        </div>
      </template>
      <div class="box answered-box" v-if="question.status === 'ANSWERED'">
        <div class="language en" @click="copyText(question.answer)" v-if="question.answer">
          {{ question.answer }}
          <div class="tips" v-if="question.matchLanguage" :class="{red: question.matchLanguage === 'en'}">
            {{ question.matchLanguage === 'en' ? '回复的原文' : '翻译后的内容' }}
          </div>
        </div>
        <div class="language local" @click="copyText(question.answerTranslated)" :class="{red: question.matchLanguage === 'en'}">
          {{ question.answerTranslated }}
          <div class="tips" v-if="question.answer && question.matchLanguage" :class="{red: question.matchLanguage === 'local'}">
            {{ question.matchLanguage === 'local' ? '回复的原文' : '翻译后的内容' }}
          </div>
          <div class="tips" v-if="!question.matchLanguage">
            通过MercadoLibre回复，无法获取回复时的语种信息
          </div>
        </div>
        <div class="copy-tips">
          点击文本框即可复制
        </div>
      </div>
      <template v-if="question.status === 'UNANSWERED' && answerLanguage">
        <div class="answer-input" >
          <textarea  rows="7" :placeholder="answerPlaceholder" v-model="answer" @focus="onAnswerFocus" @blur="onAnswerBlur" ></textarea>
          <div class="tips" v-show="showTemplateTips">
            仅会回复分割线以上的「原文」内容，「中文」内容仅供参考对比；原文内容可直接修改
          </div>
        </div>
        <div class="question-wrap">
          <div class="template-button-wrap" :class="{disabled: templateDisabled}">
            <div class="template-button" @click="openPicker">
              <van-loading v-show="selectTemplateLoading" color="#555555" />
              <img v-show="!selectTemplateLoading" :src="require('@/assets/images/question-template.png')" alt="">
              <span>{{ selectTemplate ? selectTemplate.title : '选择快捷回复' }}</span>
              <div class="arrow">
                <van-icon name="arrow-down" />
              </div>
            </div>
          </div>
          <div class="no-template-tips" v-show="!allTemplates.length">
            还没有快捷回复模板？请前往PC端创建
          </div>
        </div>
        <van-button class="button" type="primary" @click="setAnswer">提交回复</van-button>
      </template>
    </div>
    <van-popup v-model="showQuestionTemplate" position="bottom" :style="{ height: '50%' }">
      <van-picker
        title="请选择对应的模板"
        show-toolbar
        :columns="allTemplatesTitles"
        @confirm="onTemplatesConfirm"
        @cancel="showQuestionTemplate = false"
      />
    </van-popup>
    <van-popup v-model="sameCustomerMessagesPopupVisible" position="bottom" :style="{ height: '50%' }">
      <van-picker
          title="请选择消息记录"
          show-toolbar
          :columns="sameCustomerMessages"
          @confirm="onSameMessageConfirm"
          @cancel="sameCustomerMessagesPopupVisible = false"
          class="same-message-picker"
          item-height="70px"
      >
        <template #option="message">
          <div class="item">
            <div class="image">
              <img :src="message.goodsImage" alt="">
            </div>
            <div class="info-wrap">
              <div class="title">{{ message.goodsTitle }}</div>
              <div class="info">
                <span>{{ message.createdDate }}</span>
                <span>|</span>
                <span>{{ message.statusText }}</span>
              </div>
            </div>
          </div>
        </template>
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import {getQuestion, getTemplates, setAnswer, setRead} from "@/api/question";
import {copyToClipboard} from "@/utils";
import message from "@/views/message";
import request from "@/utils/request";

export default {
  data() {
    return {
      question: {},
      answer: '',
      showQuestionTemplate: false,
      allTemplates: [],
      selectTemplate: null,
      selectTemplateLoading: false,
      // 是否显示回复输入框的红字
      showTemplateTips: false,
      sameCustomerMessages: [],
      sameCustomerMessagesPopupVisible: false,
      // 回复的语言
      answerLanguage: '',
    };
  },
  computed: {
    allTemplatesTitles: function () {
      const allTemplatesTitles = [];
      this.allTemplates.forEach(item => {
        allTemplatesTitles.push(item.title);
      })
      return allTemplatesTitles;
    },
    answerPlaceholder: function () {
      let answerPlaceholder;
      if (this.answerLanguage === 'en') {
        answerPlaceholder =  "使用英语回复\n1、英语发送后，将自动转译为当地语言，由MercadoLibre提供翻译支持\n2、需由系统发出后转移，无法实时预览翻译内容\n3、买家将收到转译后的本地语言回复\n4、一旦回复，无法撤销无法更改，请谨慎";
      } else if (this.answerLanguage === 'local') {
        answerPlaceholder = "使用当地语言回复回复\n1、请根据买家语种和站点信息，选择需要回复的语言\n2、买家将收到您所输入的原文信息\n3、一旦回复，无法撤销无法更改，请谨慎\n";
      }
      return answerPlaceholder;
    },
    templateDisabled: function () {
      return this.answerLanguage === 'en';
    },
  },
  created() {
    this.getQuestion(true);
    // this.setRead();
  },
  methods: {
    getQuestion(close = false) {
      getQuestion(this.$route.params.questionId)
        .then(res => {
          if (res.error === 0) {
            this.question = res.data;
            this.sameCustomerMessages = res.info.sameCustomerMessages;
            this.answerLanguage = res.info.answerLanguage;
            this.getAllTemplates(this.question.goods.goodsId);
          } else {
            if (close) {
              this.$router.push({
                name: 'index',
              });
            } else {
              this.$errorMessage(res.message);
            }
          }
        })
        .catch(error => {
          if (close) {
            this.$router.push({
              name: 'index',
            });
          } else {
            this.$errorMessage('网络错误');
          }
          console.log('获取问题信息失败', error);
        })
    },
    setAnswer() {

      let answer = this.answer;
      if (this.selectTemplate) {
        answer = this.selectTemplate.content;
      }

      if (!answer) {
        this.$message('请填写回答');
        return false;
      }

      const data = {
        answer: answer,
        language: this.answerLanguage,
      }

      const toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      setAnswer(this.$route.params.questionId, data)
        .then(res => {
          toast.clear();
          if (res.error === 0) {
            this.getQuestion(false);
            this.$successMessage('回答完成');
          }  else if (res.error.toString().substr(0, 1) === '3' || res.error === 404) {
            this.$errorMessage(res.message);
            if (res.error === 404) {
              this.$router.push({
                name: 'index',
              });
            } else {
              this.getQuestion(true);
            }
          } else {
            this.$errorMessage(res.message);
          }
        })
        .catch(error => {
          toast.clear();
          this.$errorMessage('网络错误');
          console.error('回答错误', error);
        })
    },
    // 跳转商品页
    toGoodsOriginPage(goodsId) {
      window.open(  `https://articulo.mercadolibre.com.mx/${goodsId.substr(0,3)}-` + goodsId.substr(3), '_blank')
    },
    /*setRead() {
      setRead(this.$route.params.questionId)
        .then(res => {
          if (res.error !== 0) {
            console.error('设置已读状态失败', res.message);
          }
        })
        .catch(error => {
          console.error('设置已读状态失败', error);
        })
    },*/
    copyText(text) {
      copyToClipboard(text)
        .then(() => {
          this.$message('复制成功', 900);
        })
        .catch(() => {
          this.$errorMessage('复制失败，请手动选中复制');
        })
    },
    toHistoryPage() {
      this.$router.replace({
        name: 'questionHistory',
        params: {
          questionId: this.$route.params.questionId,
        }
      });
    },

    // 打开选择面板
    openPicker() {
      if (!this.selectTemplateLoading && !this.templateDisabled) {
        this.showQuestionTemplate = true;
      }
    },

    // 获取全部模板信息
    getAllTemplates(goodsId) {
      this.selectTemplateLoading = true;
      getTemplates(goodsId)
        .then(res => {
          this.selectTemplateLoading = false;
          if (res.error === 0) {
            this.allTemplates = res.data;
          } else {
            this.allTemplates = [];
            console.log('获取全部模板信息错误', res.message)
          }
        })
        .catch(error => {
          this.allTemplates = [];
          this.selectTemplateLoading = false;
          console.log('获取全部模板信息错误', error)
        })
    },

    // 选中特定模板
    onTemplatesConfirm(value, index) {
      this.showQuestionTemplate = false;
      if (this.allTemplates[index]) {
        this.selectTemplate = this.allTemplates[index];
        this.answer = `${this.selectTemplate.content}`
        if (this.selectTemplate.contentCn) {
          this.showTemplateTips = true;
          this.answer += `\n===================\n${this.selectTemplate.contentCn}`;
        } else {
          this.showTemplateTips = false;
        }
      }
    },

    // 输入框获焦点时
    onAnswerFocus() {
      this.showTemplateTips = false;
      if (this.selectTemplate) {
        this.answer = `${this.selectTemplate.content}`
      }
    },

    // 输入框失去焦点时
    onAnswerBlur() {
      if (this.selectTemplate) {
        if (this.answer === this.selectTemplate.content) {
          this.answer = `${this.selectTemplate.content}`
          if (this.selectTemplate.contentCn) {
            this.showTemplateTips = true;
            this.answer += `\n================\n${this.selectTemplate.contentCn}`;
          } else {
            this.showTemplateTips = false;
          }
        } else {
          this.selectTemplate = null;
        }
      }
    },

    // 显示相同买家售后信息选择
    showSameMessagePopup() {
      if (this.sameCustomerMessages.length === 1) {
        this.onSameMessageConfirm(this.sameCustomerMessages[0]);
        this.sameCustomerMessagesPopupVisible = false;
      } else {
        this.sameCustomerMessagesPopupVisible = true;
      }
    },

    // 选中对应信息
    onSameMessageConfirm(message) {
      this.$router.push({
        name: 'messageDetail',
        params: {
          orderId: message.mainId,
          oUserId: message.oUserId,
        }
      });
    },

    // 设置回复语言
    setLanguage(language) {
      this.answerLanguage = language;
      this.selectTemplate = null;
      request({
        url: `question/${this.question.questionId}/setting/language`,
        method: 'post',
        data: {
          value: language,
        }
      })
          .then(res => {
            if (res.error !== 0) {
              this.$errorMessage(res.message);
            }
          });
    },
  }
}
</script>

<style lang="less" scoped>
  .wrap{
    padding: .8rem;
  }
  .status{
    text-align: left;
    font-size: .9rem;
    .handled{
      padding: .3rem .2rem;
      background-color: #e5f1f0;
    }
    .un-handle{
      padding: .3rem .2rem;
      background-color: #fdf4e9;
    }
  }
  .goods{
    text-align: left;
    padding: .8rem 0 0;
    margin: 0 0 .05rem;
    .title{
      font-size: .9rem;
    }
    .goods-wrap{
      font-size: .8rem;
      display: flex;
      .image{
        flex: 0 0 auto;
        width: 3.8rem;
        height: 3.8rem;
        background-color: #666666;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .info{
        padding-left: .5rem;
        .title{
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          height: 3.9rem;
          line-height: 1.3rem;
        }
      }
    }
    .other{
      padding: .2rem 0;
      font-size: .8rem;
      color: #999999;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #F5F7FA;
      .lt{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .rt{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .site{
        position: relative;
        padding-right: 2px;
        width: 35px;
        img{
          width: 33px;
          height: 33px;
        }
        .full{
          position: absolute;
          bottom: 2px;
          right: -4px;
          font-size: 10px;
          color: #2c793a;
          font-weight: bolder;
        }
      }
    }
  }
  .text{
    color: #000000;
    text-align: left;
    padding: 0.3rem 0 0;
    font-size: .9rem;
    .title-wrap{
      display: flex;
      justify-content: space-between;
      .image-button{
        height: 1.6rem;
        padding: .5rem .6rem;
      }
      .same-message{
        margin-right: 10px;
      }
    }
    .title{
      padding: .3rem 0;
    }
    .box{
      font-size: .8rem;
      border: 1px solid #cccccc;
      border-radius: .3rem;
      padding: .8rem .5rem;
      position: relative;
      .copy-tips{
        color: #FBB562;
        font-size: 10px;
        position: absolute;
        bottom: 2px;
        right: 2px;
      }
    }
    .ext{
      font-size: .8rem;
      text-align: right;
      color: #aaaaaa;
      padding: .2rem 0;
    }
    textarea{
      font-size: .8rem;
      width: 100%;
      box-sizing: border-box;
      padding: .8rem .5rem;
      border: 1px solid #cccccc;
      border-radius: .3rem;
    }
    .button{
      background-color: #459ad0;
      border: none;
      height: 1.8rem;
      padding: 0 1.6rem;
      margin-top: .8rem;
      border-radius: .3rem;
    }
    .answer-input{
      .tips{
        font-size: .6rem;
        color: #ff0000;
      }
    }
    .question-wrap{
      padding: .6rem 0 .8rem;
      .template-button-wrap{
        display: inline-block;
        .template-button{
          padding: 2px 12px;
          display: flex;
          align-items: center;
          border: 1px solid #d7d7d7;
          border-radius: 3.8px;
          img{
            width: 24px;
            margin-right: 5px;
          }
          span{
            margin-right: 5px;
            color: #555555;
            white-space: nowrap;
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: .8rem;
          }
          .arrow{
            padding: 6px 0 0;
            color: #d5d7dc;
          }
        }
        &.disabled{
          opacity: .5;
        }
      }
      .no-template-tips{
        color: #ff0000;
        font-size: .6rem;
        padding: 3px 0;
      }
    }
  }
  .block{
    padding: .6rem 0 0;
    border-bottom: 1px solid #F5F7FA;
    margin: 0 0 .6rem;
  }
  .same-message-picker{
    .item{
      width: 100%;
      display: flex;
      cursor: pointer;
      box-sizing: border-box;
      border: 1px solid #ffffff;
      padding: 10px 0 10px 20px;
      .image{
        margin-right: 8px;
        @imageWidth: 42px;
        img{
          width: @imageWidth;
          height: @imageWidth;
        }
      }
      .info-wrap{
        .title{
          text-align: left;
          margin-bottom: 3px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .info{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: left;
          color: #aaaaaa;
          display: flex;
          span{
            margin-right: 20px;
          }
        }
      }
    }
  }
  .answered-box{
    border: none !important;
    padding: 0 0 1rem !important;
    .language{
      border: 1px solid #cccccc;
      border-radius: 0.3rem;
      padding: 0.8rem 0.5rem 1.6rem;
      margin-bottom: .8rem;
      position: relative;
      .tips{
        position: absolute;
        font-size: .7rem;
        bottom: .2rem;
        left: .5rem;
        color: #AAAAAA;
        &.red{
          color: red;
        }
      }
      &.en{
        background: #FFFFF8;
      }
      &.local {
        background: #F7FFFF;
      }
    }
  }
  .select-language{
    display: flex;
    border: 1px solid #cccccc;
    border-radius: 0.3rem;
    .language{
      padding: 1rem 1rem;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title{
        font-weight: bold;
        margin-bottom: .5rem;
      }
      img{
        width: 60%;
      }
      &.en{
        background: #FFFFF8;
      }
      &.local {
        background: #F7FFFF;
      }
    }
  }
  .text-wrap{
    display: flex;
    align-items: center;
    padding: 0 0 .5rem;
    .title{
      margin-right: 1rem;
    }
    .buttons{
      display: flex;
      align-items: center;
      .lang{
        color: #333333;
        border: 1px solid #cccccc;
        border-radius: 0.2rem;
        font-size: .8rem;
        padding: .1rem .3rem;
        margin-right: 1.2rem;
        &.current{
          color: #ffffff;
          background: #0B90FF;
          border-color: #0B90FF;
        }
      }}

  }
</style>
