import request from "@/utils/request";

export function getQuestionList(handle, goodsId, page, pageSize) {
  return request({
    url: '/question',
    params: {
      handle,
      goodsId,
      page,
      pageSize
    }
  })
}

export function getQuestion(questionId) {
  return request({
    url: '/question/' + questionId,
  });
}

export function setRead(questionId) {
  return request({
    url: '/question/' + questionId + '/read',
    method: 'post',
  })
}

export function setAnswer(questionId, data) {
  return request({
    url: '/question/' + questionId + '/answer',
    method: 'post',
    data: {
      ...data,
    }
  });
}

export function getHistory(questionId) {
  return request({
    url: `/question/${questionId}/history`,
  });
}

export function getTemplates(goodsId) {
  return request({
    url: `/question/goods/${goodsId}/templates`
  });
}
